<template>
  <router-view></router-view>
</template>
<script>

export default {
    setup(props, context){
        const g = context.root

        if(!g.$cookies.get('token') && g.$route.name != 'pemeriksaanPemeriksaanDownload'){
          g.$router.replace({ name: 'login' })
        }
    },
}
</script>
